import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { lowerCase } from 'utils';
import Ts from 'localeTranslator';
import { Icon, Dropdown, Menu } from 'antd';
import { reaction, computed } from 'mobx';
import ServiceModal from './ServiceModal';
import { coinsConfig } from '@/utils/config';
import queryString from 'qs';
import './index.scss';
@withRouter
@inject('store')
@observer
export default class HeaderNavigation extends Component {
  state = {
    accountOpen: false,
    path: '',
    serviceModalVisible: false,
    earningNav: {
      title: <Ts id="common.setting.menu.earingsHistory" />,
      id: 'earning',
      isShow: true,
      isRouting: true // 执行路由
    },
    mergeMiningEarningNav: {
      title: (
        <>
          DOGE <Ts id="common.setting.menu.earingsHistory" />
        </>
      ),
      id: 'doge-earning',
      search: 'coinType=DOGE',
      isShow: false,
      isRouting: true // 执行路由
    }
  };

  static defaultProps = {
    isHome: false
  };

  @computed
  get nomalNavs() {
    return [];
    // return [
    //   {
    //     title: <Ts id="common.header.poolStats" />,
    //     url: '/pool-stats',
    //     id: 'poolStats',
    //     showInMore: true,
    //     isRouting: true // 执行路由
    //   },

    //   {
    //     title: (
    //       <Fragment>
    //         <Ts id="common.header.service" />
    //       </Fragment>
    //     ),
    //     url: 'javascript:;',
    //     id: 'service',
    //     isRouting: false,
    //     showInMore: true,
    //     target: '_self'
    //   },
    //   {
    //     title: <Ts id="common.header.tools" />,
    //     url: '/tools',
    //     id: 'tools',
    //     showInMore: true,
    //     isRouting: true // 执行路由
    //   },
    //   {
    //     title: <Ts id="common.header.help" />,
    //     url: `https://help.cloverpool.com/hc/${lowerCase(
    //       this.appStore.lang || 'zh-cn'
    //     )}`,
    //     id: 'help',
    //     showInMore: true,
    //     isRouting: false
    //   },
    //   {
    //     title: <Ts id="common.header.bitdeer" />,
    //     url: '/bitdeer',
    //     id: 'bitdeer',
    //     hideInHomepage: false,
    //     showInMore: true,
    //     isRouting: true // 执行路由
    //   },
    //   {
    //     title: <Ts id="common.header.app" />,
    //     url: '/app-download',
    //     id: 'app-download',
    //     hideInHomepage: false,
    //     showInMore: false,
    //     isRouting: true // 执行路由
    //   }
    // ];
  }

  @computed
  get authNavs() {
    return [
      {
        title: <Ts id="common.header.dashboard" />,
        url: '/dashboard',
        id: 'dashboard',
        isShow: true,
        isRouting: true // 执行路由
      },
      {
        title: <Ts id="common.header.miners" />,
        url: '/miners',
        id: 'mill',
        isShow: true,
        isRouting: true // 执行路由
      },
      this.state.earningNav,
      this.state.mergeMiningEarningNav
      // {
      //   title: <Ts id="common.header.getCoin" />,
      //   url: '/merge/getCoin',
      //   id: 'getCoin',
      //   isRouting: true // 执行路由
      // }
      // {
      //   title: <Ts id="common.header.app" />,
      //   url: '/app-download',
      //   id: 'app-download',
      //   isRouting: true // 执行路由
      // }
    ];
  }

  constructor(props) {
    super(props);
    this.userStore = this.props.store.userStore;
    this.appStore = this.props.store.appStore;
    this.homepageStore = this.props.store.homepageStore;
    const { earningNav, mergeMiningEarningNav } = this.state;
    reaction(
      () => ({
        lang: this.appStore.lang,
        subaccount_type: this.userStore.accountInfo.subaccount_type,
        coin_type: this.userStore.accountInfo.coin_type
      }),
      ({ lang, subaccount_type, coin_type }) => {
        if (['bitdeer_hosting', 'bitdeer_share'].includes(subaccount_type)) {
          earningNav.children = [
            {
              title: <Ts id="common.breadCrumbs.earningHistory" />,
              url: '/earning-history',
              id: 'earningHistory',
              isRouting: true // 执行路由
            },
            {
              title: <Ts id="common.breadCrumbs.transactions" />,
              url: '/transactions',
              id: 'transactions',
              isRouting: true // 执行路由
            }
          ];
        } else {
          earningNav.url = '/earning-history';
        }
        this.setState({
          earningNav
        });

        if (lowerCase(coin_type) === 'ltc') {
          mergeMiningEarningNav.url = '/merge-mining-earning';
          mergeMiningEarningNav.isShow = true;
          this.setState({
            mergeMiningEarningNav
          });
        }
      }
    );
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    let path = nextProps.location.pathname;
    return {
      path
    };
  }

  componentDidMount() {
    this.setState({
      path: this.props.location.pathname
    });
  }

  getNavData = () => {
    const { isAuthenticated, accountInfo } = this.userStore;
    const { isHome } = this.homepageStore;
    let navData = this.nomalNavs;

    if (true) {
      navData = this.authNavs.filter(nav => {
        if (nav.id === 'getCoin') {
          if (accountInfo && accountInfo.coin_type) {
            const showMergeMining =
              coinsConfig[accountInfo.coin_type.toLowerCase()].showMergeMining;

            if (showMergeMining) {
              return true;
            }
          }
          return false;
        }
        if (!isHome && nav.showInHomepage) {
          return false;
        }
        return true;
      });
    }

    return navData;
  };

  onNavClickHandle = ({ key }) => {
    if (key !== 'service') return;
    this.setState({
      serviceModalVisible: true
    });
  };

  handleHideServiceModal = () => {
    this.setState({
      serviceModalVisible: false
    });
  };

  getTitleWithRedPoint = item => {
    const { accountInfo } = this.userStore;
    let title = item.title;
    if (item.id === 'getCoin') {
      if (accountInfo && accountInfo.merge_mining_addresses) {
        const coins = Object.keys(accountInfo.merge_mining_coins).filter(
          coin => accountInfo.merge_mining_coins[coin] !== 'beta'
        );
        coins.forEach(coin => {
          if (!accountInfo.merge_mining_addresses[coin]) {
            title = (
              <Fragment>
                <div className="position-relative">
                  {item.title}
                  <div className="red-point" />
                </div>
              </Fragment>
            );
          }
        });
      }
    }
    return title;
  };

  render() {
    const { path } = this.state;
    const { isHome } = this.homepageStore;
    const {
      isAuthenticated,
      isWatcher,
      isShared,
      isUserReadOnly
    } = this.userStore;
    let search = '';
    // 观察者、共享者，通过url判断
    if (isUserReadOnly) {
      let searchParams = {};
      searchParams = queryString.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      });
      if (isWatcher) {
        if (searchParams.access_key) {
          search = queryString.stringify(
            { access_key: searchParams.access_key, puid: searchParams.puid },
            { addQueryPrefix: true }
          );
        }
      } else if (isShared) {
        if (searchParams.puid) {
          search = queryString.stringify(
            { puid: searchParams.puid, shared: searchParams.shared },
            { addQueryPrefix: true }
          );
        }
      }
    }

    return (
      <ul className={this.props.className}>
        {this.getNavData()
          .filter(item => item.isShow)
          .map(item => {
            let url = item.url;
            let isActive = url === path || path.indexOf(url) > -1;

            if (item.children) {
              return (
                <Dropdown
                  key={item.id}
                  overlay={
                    <Menu onClick={this.onNavClickHandle}>
                      {item.children.map(childItem => {
                        return (
                          <Menu.Item key={childItem.id} className="text-center">
                            {childItem.isRouting ? (
                              <Link
                                to={{
                                  pathname: childItem.url,
                                  search: search
                                }}
                              >
                                {childItem.title}
                              </Link>
                            ) : (
                              <a
                                href={
                                  childItem.url
                                    ? childItem.url
                                    : 'javacript:void(0)'
                                }
                                target={childItem.target || '_blank'}
                              >
                                {childItem.title}
                              </a>
                            )}
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  }
                >
                  <a className="ant-dropdown-link" href="#">
                    {item.title} <Icon type="down" />
                  </a>
                </Dropdown>
              );
            }
            return (
              <li
                className={
                  isActive
                    ? 'header-nav-item header-nav-item-active'
                    : 'header-nav-item'
                }
                key={item.id}
                onClick={e => {
                  return this.onNavClickHandle({ key: item.id });
                }}
              >
                {item.isRouting ? (
                  <Link
                    to={{
                      pathname: item.url,
                      search: item.search ? `${search}&${item.search}` : search
                    }}
                  >
                    {this.getTitleWithRedPoint(item)}
                  </Link>
                ) : (
                  <a
                    href={item.url ? item.url : 'javacript:void 0'}
                    target={item.target || '_blank'}
                  >
                    {item.title}
                  </a>
                )}
              </li>
            );
          })}
        {/* {isAuthenticated && (
          <Dropdown
            overlay={
              <Menu onClick={this.onNavClickHandle}>
                {this.nomalNavs
                  .filter(item => {
                    if (isHome) {
                      return item.showInMore && !item.hideInHomepage;
                    }
                    return item.showInMore;
                  })
                  .sort((a, b) => {
                    if (a.hideInHomepage) {
                      return -1;
                    } else if (b.hideInHomepage) {
                      return 1;
                    }
                    return 0;
                  })
                  .map(item => {
                    return (
                      <Menu.Item key={item.id} className="text-center">
                        {item.isRouting ? (
                          <Link
                            to={{
                              pathname: item.url,
                              search: search
                            }}
                          >
                            {item.title}
                          </Link>
                        ) : (
                          <a
                            href={item.url ? item.url : 'javacript:void(0)'}
                            target={item.target || '_blank'}
                          >
                            {item.title}
                          </a>
                        )}
                      </Menu.Item>
                    );
                  })}
              </Menu>
            }
          >
            <a className="ant-dropdown-link" href="#">
              <Ts id="common.more" /> <Icon type="down" />
            </a>
          </Dropdown>
        )} */}
        <ServiceModal
          visible={this.state.serviceModalVisible}
          onCancel={this.handleHideServiceModal}
        />
      </ul>
    );
  }
}
