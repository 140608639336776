import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Modal, Icon, Dropdown, Menu } from 'antd';
import Cookies from 'js-cookie';
import Ts from 'localeTranslator';
import { I18N_LANG } from '@/utils/constants';
import Navigation from '@/components/Navigation';
import { lowerCase } from 'utils';
import UserList from '@/components/Header/section/userList';
import Setting from '@/components/Header/section/setting';
import GrinSwitch from '@/components/Header/section/GrinSwitch';
import LoadingIcon from '@/components/widget/LoadingIcon';
import sensors from 'sa-sdk-javascript';
import ReactGA from 'react-ga';
import './index.scss';

@withRouter
@inject('store')
@observer
class HomePageHeader extends Component {
  state = {
    loading: false,
    visible: false,
    modalType: 'signIn',
    isShowIframeOverlay: true
  };

  constructor(props) {
    super(props);
    this.userStore = this.props.store.userStore;
    this.appStore = this.props.store.appStore;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isAuthenticated, isRefreshingNewToken } = nextProps.store.userStore;
    const location = nextProps.location;
    // 根据url hash 弹出登录、注册窗口
    if (location && location.hash) {
      if (!isAuthenticated) {
        if (['#login', '#register'].indexOf(location.hash) > -1) {
          if (isRefreshingNewToken) {
            return null;
          }
          nextProps.history.replace(location.pathname, location.state);
          return {
            visible: true,
            modalType:
              nextProps.location.hash === '#register' ? 'signUp' : 'signIn'
          };
        }
      } else if (!isRefreshingNewToken && location.pathname === '/') {
        if (location.state && location.state.from) {
          const from = location.state.from;
          nextProps.history.replace(from.pathname, from.state);
        }
      }
    }

    return null;
  }

  showModal = (e, modalType = 'signIn') => {
    this.setState({
      visible: true,
      modalType,
      isShowIframeOverlay: modalType !== this.state.modalType
    });
  };

  handleCancel = () => {
    this.setState({ visible: false, isShowIframeOverlay: true });
  };

  receiveMessageFromIframePage = event => {
    if (event && event.data) {
      if (event.data.event === 'close iframe') {
        const date = new Date();
        date.setDate(date.getDate() + 15);
        Cookies.set('user-jwt-expire', date.getTime(), {
          expires: 15
        });
        this.userStore.login(subAccountInfo => {
          // 判断是否有子账户，没有就回跳转创建页面
          if (!subAccountInfo.puid) {
            this.props.history.push('/sub-account/create');
          }
          // 登录完成后，跳转回原地址
          const location = this.props.location;
          if (location.state && location.state.from) {
            const from = location.state.from;
            this.props.history.push(from);
          }
          // 刚注册完的账户没有uid
          if (subAccountInfo.uid) {
            sensors.login(subAccountInfo.uid);
          }
        });
        this.setState({
          visible: false,
          isShowIframeOverlay: true
        });
      }
    }
  };

  logout = () => {
    this.userStore.logout();
  };

  handleFileLoad = () => {
    this.setState({
      isShowIframeOverlay: false
    });
  };

  getUserDropDownMenu() {
    const { accountInfo } = this.userStore;
    let avatar = null;
    if (accountInfo.user_info && accountInfo.user_info.avatar_pic) {
      avatar = <img src={accountInfo.user_info.avatar_pic} alt="" />;
    }
    return (
      <div className="header-content__user-dropdown">
        <div className="header-content__user-dropdown__avatar">{avatar}</div>
        <div className="header-content__user-dropdown__link-user">
          <Menu>
            <Menu.Item>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.BTC_POOL_USER_CENTER}
              >
                <Ts id="common.header.userCenter" />
              </a>
            </Menu.Item>
            <Menu.Item>
              <a
                target="_blank"
                rel="noopener noreferrer"
                onClick={this.logout}
              >
                <Ts id="common.header.SignOut" />
              </a>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    );
  }

  getAuthenticatedUserDisplay() {
    const { accountInfo, isUserReadOnly } = this.userStore;
    if (accountInfo.name || isUserReadOnly) {
      return (
        <div className="header-content-right">
          {/* 如果是 grin 显示grin 算法切换 */}
          {/* {lowerCase(accountInfo.coin_type) === 'grin' && <GrinSwitch />} */}

          <UserList />

          {!isUserReadOnly && (
            <div className="header-right-setting">
              <Setting
                puid={accountInfo.puid}
                coinType={accountInfo.coin_type}
              />
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="header-content__user">
        <Dropdown trigger={['click']} overlay={this.getUserDropDownMenu()}>
          <button className="ant-dropdown-link">
            {accountInfo.name ? (
              <div className="header-content-right">
                {/* 如果是 grin 显示grin 算法切换 */}
                {lowerCase(accountInfo.coin_type) === 'grin' && <GrinSwitch />}

                <UserList />

                {!isUserReadOnly && (
                  <div className="header-right-setting">
                    <Setting
                      puid={accountInfo.puid}
                      coinType={accountInfo.coin_type}
                    />
                  </div>
                )}
              </div>
            ) : accountInfo.user_info.mail ? (
              <span>{accountInfo.user_info.mail}</span>
            ) : (
              <span>{accountInfo.user_info.phone.number}</span>
            )}
            <Icon type="caret-down" />
          </button>
        </Dropdown>
      </div>
    );
  }

  sendEvent = (eventAction, eventLabel) => {
    sensors.track(eventAction, {
      page_source: eventLabel
    });
    ReactGA.event(
      {
        category: 'Account',
        action: eventAction,
        label: eventLabel
      },
      ['poolGA']
    );
    global._hmt &&
      global._hmt.push(['_trackEvent', 'Account', eventAction, eventLabel]);
  };

  componentDidMount() {
    //监听message事件
    window.addEventListener(
      'message',
      this.receiveMessageFromIframePage,
      false
    );
  }

  render() {
    const { visible, modalType, isShowIframeOverlay } = this.state;
    const { isAuthenticated } = this.userStore;
    const { lang } = this.appStore;
    const ucLang = lang === I18N_LANG.zh ? 'zh-cn' : 'en';
    return (
      <div className="homepage-header" style={this.props.style}>
        <div className="homepage-header-content">
          <div className="homepage-header-content-left">
            <h1 className="homepage-header-logo">
              <Link to="/?jump=logo">CloverPool</Link>
            </h1>
            <Navigation className="homepage-header-nav" isHome={true} />
          </div>
          <div className="homepage-header-content-right">
            {
              <div className="header-login-container">
                {!isAuthenticated ? (
                  <div className="header-content__login-btns">
                    <button
                      className="header-content__sign-in"
                      onClick={this.showModal}
                    >
                      <Ts id="pages.homepage.signIn" />
                    </button>
                    <button
                      className="header-content__sign-up"
                      onClick={e => {
                        this.showModal(e, 'signUp');
                      }}
                    >
                      <Ts id="pages.homepage.signUp" />
                    </button>
                  </div>
                ) : (
                  this.getAuthenticatedUserDisplay()
                )}
              </div>
            }
          </div>
        </div>
        <Modal
          className="login-modal"
          width="360"
          height="590"
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          closable={false}
        >
          <div
            className={`login-frame ${isShowIframeOverlay &&
              'overlay-container'}`}
            key={modalType}
          >
            {!isAuthenticated && (
              <Fragment>
                {isShowIframeOverlay && (
                  <div className="overlay-content">
                    <LoadingIcon />
                  </div>
                )}
                <iframe
                  id="login_iframe"
                  title="user center"
                  onLoad={this.handleFileLoad}
                  // src="test.html"
                  src={
                    process.env.BTC_POOL_USER_CENTER +
                    `?lang=${ucLang}` +
                    (modalType === 'signUp' ? '/#/sign-up' : '')
                  }
                />
              </Fragment>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}
export default HomePageHeader;
