import { version } from '../package.json';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';
import sensors from 'sa-sdk-javascript';
import IntlProviderWrap from './components/IntlProviderWrap';
import stores from './stores';
import App from './App';
import history from '@/utils/history';
import '@/utils/ajax';
import { mobile as isMobile } from '@/utils/browserDetect';
import './icons'; // svg icon
import * as serviceWorker from './serviceWorker';
const isProduction = process.env.NODE_ENV === 'production';
console.info(
  `%cCloverPool Web Version:${version}`,
  'font-size: 24px; background: #3c78c2; color: #fff; font-weight: bold;padding: 5px;'
);
const root = document.getElementById('root');

if (root && isMobile) {
  root.classList.add('page-mobile');
}

// sensor track
sensors.init({
  server_url: `https://sensors-sdk.cloverpool.com/sa${
    isProduction ? '?project=production' : ''
  }`,
  heatmap: {
    //是否开启点击图，默认 default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭
    //需要 JSSDK 版本号大于 1.7
    clickmap: 'default',
    //是否开启触达注意力图，默认 default 表示开启，自动采集 $WebStay 事件，可以设置 'not_collect' 表示关闭
    //需要 JSSDK 版本号大于 1.9.1
    scroll_notice_map: 'not_collect'
  },
  is_track_device_id: true,
  show_log: false
});
sensors.quick('autoTrack');

ReactDOM.render(
  <Provider store={stores}>
    <IntlProviderWrap>
      <Router key={Math.random()} history={history}>
        <App />
      </Router>
    </IntlProviderWrap>
  </Provider>,
  root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
