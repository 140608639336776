const isProduction = process.env.NODE_ENV === 'production';
const baseURL = isProduction
  ? process.env.PUBLIC_URL || process.env.BTC_POOL_URL
  : process.env.BTC_POOL_PROXY_TARGET;
const helpBaseURL = 'https://help.cloverpool.com';

export { isProduction, baseURL, helpBaseURL };

const COMMON_MAX_LIMIT_LENGTH = 100;

const LIMIT_GROUP_NAME_LENGTH = {
  max: 20,
  min: 3
};

const HASHRATE_UNIT_ARRAY = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

const HASHRATE_UNIT_MAPPING = HASHRATE_UNIT_ARRAY.reduce(function(acc, cur, i) {
  acc[cur] = Math.pow(10, 3 * i);
  // min hashrate set to 0
  if (acc[cur] === 1) {
    acc[cur] = 0;
  }
  return acc;
}, {});
// only could read property, could not enumerable
Object.defineProperty(HASHRATE_UNIT_MAPPING, 'minScaleUnit', {
  enumerable: false,
  configurable: false,
  writable: false,
  value: { key: '', value: HASHRATE_UNIT_MAPPING[''] }
});
Object.defineProperty(HASHRATE_UNIT_MAPPING, 'maxScaleUnit', {
  enumerable: false,
  configurable: false,
  writable: false,
  value: { key: 'Y', value: HASHRATE_UNIT_MAPPING['Y'] }
});

// grin 默认算法模式
const GRIN_DEFAULT_ALGORITHM = '29';

// 语言枚举
const I18N_LANG = {
  zh: 'zh-CN',
  en: 'en-US'
};

export {
  COMMON_MAX_LIMIT_LENGTH,
  LIMIT_GROUP_NAME_LENGTH,
  HASHRATE_UNIT_MAPPING,
  HASHRATE_UNIT_ARRAY,
  GRIN_DEFAULT_ALGORITHM,
  I18N_LANG
};
